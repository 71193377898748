import React from "react";
import AnimateCC from "react-adobe-animate";
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import "../Contact.scss";
import {
	Link,
} from "react-router-dom";

function Thanks() {
	return (
		<>
			<div className="title-flex">
				<ContactCloud />
				<ContactTitle />
			</div>
			<div className="container">
				<div className="contact-flex">
					<div className="contact-flex-text">
						&nbsp;
					</div>
					<div className="contact-flex-progress">
						<img src={`${process.env.PUBLIC_URL}/images/contact/progress03.svg`} alt="end" />
					</div>
				</div>
				<div className="contact-thanks">
					お問い合わせありがとうございました。<br />
					内容の確認後、折り返し担当者よりご連絡させていただきます。
				</div>
			</div>
			<ContactFooer />
		</>
	);
}
function ContactCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function ContactTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="contact-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-contact.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}

function ContactFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="contactfooter" composition="910D0EA391701E4089234698376BC213" />
			</div>

		</>
	);
}

export default Thanks;
