import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import AnimateCC from "react-adobe-animate";
import queryString from 'query-string';
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import "./Works.scss";
import {
	Link,
} from "react-router-dom";

function Works (){
	const search = useLocation().search;
	const query3 = queryString.parse(search);
	const urltext = "https://web-shop.co.jp/worklist/wp-json/wp/v2/";
	const client = Array();
	const worktype = Array();
	const [cdata, setCategory] = useState(0);
	const [data, setData] = useState([]);
	var qString;
	var lnum = 0;
	const [thispos, posChange] = useState([]);
	var listary = Array();
	useLayoutEffect(() => {
		if(!cdata){
		axios
			.get(urltext + "categories?per_page=100")
			.then(response => setCategory(response.data))
			.catch(error => console.log(error));
		}
	}, []);
	for (var i = 0; i < cdata.length; i++) {
		if (cdata[i].id != 2) {
			switch (cdata[i].parent) {
				case 1: worktype.push(cdata[i]); break;
				case 10: client.push(cdata[i]); break;
				default: break;
			}
		}
	}
	qString = "posts?_embed&per_page=100";
	var cont;
	var jsonurl = (urltext + qString);
	const workArray = Array();
	let nowPos = 0;
	var allResponse = [];
	var viewContents;
	var pp = 3;
	if (query3["c"] == "tc") {
		pp = 1;
	}
	function getCategory(cat) {
			lnum++;
			return Promise.all(
				cat.map((cat) =>
					axios.get(urltext + "posts", { params: { categories: cat.id, per_page: pp } })
				)
			).then((responses) => {
				for (const res of responses) {
					// 必要ならこの辺でレスポンスを加工
					allResponse[res.config.params.categories] = res.data;
				}
				//return Promise.resolve(allResponse);
				viewContents = setElement(allResponse, query3["c"]);
				setData(viewContents);
			});
	}
	function getAll() {
			lnum++;
			axios.get(urltext + qString)
				.then((responses) => {
					// 必要ならこの辺でレスポンスを加工
					allResponse = responses.data;
					viewContents = setElement(allResponse, query3["c"]);
					setData(viewContents);
				});
	}
		var allResponse = [];
		getCategory(worktype, query3["c"]);
	function setElement(val, qry) {
		var settext = "";
			for (let s = 0; s < worktype.length; s++) {
				if (val[worktype[s].id].length > 0) {
					settext += '<div class="works-list-title">' + worktype[s].name + '</div>\n';
					settext += '<div class="works-list">'
					for (let ss = 0; ss < val[worktype[s].id].length; ss++) {
						settext += '<div class="works-list-col">\n';
						/*settext += '<div className="works-list-title">' + val[worktype[s].id][ss].title.rendered + '</div>\n';*/
						settext += '<div class="works-list-container">\n';
						settext += '<a href="/worksdetail/?p=' + val[worktype[s].id][ss].id + '">\n';
						settext += '<img src="' + val[worktype[s].id][ss].acf.photo1.replace(".jpg","-300x300.jpg") + '" alt="' + val[worktype[s].id][ss].title.rendered + '" />\n';
						settext += '</a>\n';
						settext += '</div>\n';
						settext += '</div>\n';
					}
					settext += "</div>";
				}
			}
		return settext;
	}
	return (
		<>
			<div className="title-flex">
				<WorksCloud />
				<WorksTitle />
			</div>
			<div className="container">
				<Worksblock01 />
				<div className="works" dangerouslySetInnerHTML={{ __html: data }} />
			</div>
			<ContentsFooer />
		</>
	);
}
function WorksCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function WorksTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="service-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-works.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}
function Worksblock01() {
	return (
		<>
			<div className="submenu">
				<div className="submenu-btn">
				<Link to="/works/" className="active">TYPE OF WORK</Link>
					<Link to="/worksclient/">TYPE OF CLIENT</Link>
					<Link to="/worksall/">ALL</Link>
				</div>
			</div>

		</>
	)
}
function Worksblock02() {
	return (
		<>
			<div className="works-list">

			</div>
		</>
	)

}
function ContentsFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="servicefooter" composition="41BCB5191BDAA0419F21A0A95352CCE6" />
			</div>

		</>
	);
}

export default Works;
