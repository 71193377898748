import React from "react";
import AnimateCC from "react-adobe-animate";
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import Zoom from 'react-reveal/Zoom';
import "./Service.scss";
import {
	Link,
} from "react-router-dom";

function Service() {
	return (
		<>
			<div className="title-flex">
			<ServiceCloud />
			<ServiceTitle />
			</div>
			<ServiceBlock01 />
			<ServiceBlock02 />
			<ServiceBlock03 />
			<ServiceBlock04 />
			<ServiceBlock05 />
			<ServiceBlock06 />
			<ContentsFooer />
		</>
	);
}
function ServiceCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function ServiceTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="service-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-service.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}
function ServiceBlock01() {
	return (
		<>
			<div className="service-header">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-header-flex rv">
							<div className="service-header-col bg">
								<h4 className="service-h4">面倒なコト、わからないコト<br />なんでもお任せください。</h4>
								<p className="service-text">ちょっとしたアイデアやちょっとしたみせ方で<br />
									企業の魅力は劇的に変わります。<br />
									企業の想いを表現、形にし、ひとりでも多くの方に伝わるように、企業を盛り上げるお手伝いをすることが私たちの役目だと思っています。<br />
									<br />
									お買い物をするときのように楽しく、ワクワクしながら企業づくりができるよう、私たちは日々成長し続けていくことで社会に貢献し続けていきます。</p>
							</div>
							<div className="service-header-col img">
								<img src={`${process.env.PUBLIC_URL}/images/service/service-img01.svg`} alt="web design produce&amp;SEO" />
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function ServiceBlock02() {
	return (
		<>
			<div className="service-support">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/totalsupport.svg`} alt="Total support" /></h3>
						</div>
					</Reveal>
					<div className="service-support-flex rv">
						<div className="service-support-col">
							<Reveal animation={Animation.FadeInUp}>
								<p className="service-text">ホームページを持つ一番の目的はその企業やお店のことを知ってもらうことです。<br />
									一方通行の伝達だからこそ見ている人にとってわかりやすく、<br />
									そして知りたい情報を言葉以上に明確に伝えることが大事です。<br />
									webshopでは、web制作はもちろん、ブランディング、マーケティング、運用まで総合的にトータルサポートをしております。<br />
									専門知識を備えたプロ集団だからこその視点で、お客様の企業や商品をたくさんの人に知ってもらうお手伝いをいたします。</p>
							</Reveal>
						</div>
						<div className="service-support-col figure">
							<div className="blue">
								<div className="blue-left">集客</div>
								<div className="blue-right">リピート</div>
							</div>
							<div className="circle">
								<div className="circle-top">
									<Zoom delay={300} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">WEB制作<span className="circle-title-eng">Production</span></h4>
											</div>
											<div className="circle-col">
												<ul className="circle-list">
													<li className="icon-text">コーポレートサイト</li>
													<li className="icon-text">採用サイト</li>
													<li className="icon-text">ランディングページ</li>
													<li className="icon-text">サービス紹介サイト</li>
													<li className="icon-text">ECサイト</li>
													<li className="icon-text">マッチングサイト</li>
													<li className="icon-text">システム制作</li>
												</ul>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-left">
									<Zoom delay={500} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">マーケティング<span className="circle-title-eng">Marketing</span></h4>
											</div>
											<div className="circle-col">
												<div className="marketing-flex">
													<div className="marketing-col">
														<h5 className="title">検索エンジン</h5>
														<ul className="circle-list">
															<li className="icon-text">SEO・SEM</li>
															<li className="icon-text">リスティング</li>
														</ul>
														<h5 className="title">ポスティング広告</h5>
													</div>
													<div className="marketing-col">
														<h5 className="title">SNS 運用</h5>
														<ul className="circle-list">
															<li className="icon-text">facebook 投稿代行</li>
															<li className="icon-text">インスタグラム投稿代行</li>
															<li className="icon-text">YouTube動画制作</li>
															<li className="icon-text">広告運用</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-right">
									<Zoom delay={700} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">運用<span className="circle-title-eng">Operation</span></h4>
											</div>
											<div className="circle-col">
												<ul className="circle-list">
													<li className="icon-text">LINE公式アカウント運用代行</li>
													<li className="icon-text">メルマガ/DM制作<br /> （制作～発送代行まで）</li>
													<li className="icon-text">顧客管理システム</li>
												</ul>
											</div>
										</div>
									</Zoom>
								</div>
								<div className="circle-bottom">
									<Zoom delay={700} duration={500}>
										<div className="circle-flex">
											<div className="circle-col">
												<h4 className="circle-title">ブランディング<span className="circle-title-eng">Branding</span></h4>
											</div>
											<div className="circle-col">
												<div className="marketing-flex">
													<div className="marketing-col">
														<ul className="circle-list">
															<li className="icon-text">ロゴ</li>
															<li className="icon-text">パンフレット</li>
															<li className="icon-text">チラシ</li>
															<li className="icon-text">冊子</li>
														</ul>
													</div>
													<div className="marketing-col">
														<ul className="circle-list">
															<li className="icon-text">名刺</li>
															<li className="icon-text">イラスト</li>
															<li className="icon-text">パッケージ</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Zoom>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
function ServiceBlock03() {
	return (
		<>
			<div className="service-production bg2">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-subtitle rv">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/production.svg`} alt="production" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-production-flex rv">
							<div className="service-production-col img">
								<img src={`${process.env.PUBLIC_URL}/images/service/production-img01.svg`} alt="production" />
							</div>
							<div className="service-production-col">
								<h4 className="service-h4">企業の想いやコンセプトをwebを<br />通してしっかり伝えます。</h4>
								<p className="service-text">ホームページは企業やお店の顔です。<br />
									コンセプトに基づき、見てほしいユーザーさんにとって、魅力のあるコンテンツを一緒に考え、ご提案いたします。</p>
								<div className="col-flex">
									<img src={`${process.env.PUBLIC_URL}/images/service/production-text01.svg`} alt="Plannning 企画" />
									<img src={`${process.env.PUBLIC_URL}/images/service/production-text02.svg`} alt="Strategy 戦略" />
									<img src={`${process.env.PUBLIC_URL}/images/service/production-text03.svg`} alt="Consulting コンサルティング" />
								</div>
								<div className="icon-flex">
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/production-icon01.svg`} alt="ホームページ" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/production-icon02.svg`} alt="ランディングページ" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/production-icon03.svg`} alt="ショッピングサイト" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/production-icon04.svg`} alt="システム開発" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/production-icon05.svg`} alt="動画" />
									</div>
									<div className="icon-col nbg">&nbsp;</div>
								</div>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function ServiceBlock04() {
	return (
		<>
			<div className="service-branding bg1">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/branding.svg`} alt="production" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-branding-flex">
							<div className="service-branding-col img">
								<img src={`${process.env.PUBLIC_URL}/images/service/branding-img01.svg`} alt="branding" />
							</div>
							<div className="service-branding-col">
								<h4 className="service-h4">企業のDNAをデザインで<br />見える化いたします。</h4>
								<p className="service-text">何を訴えたいのか、届けたい相手は誰なのか、自分たちの強み、<br />
									他社との違いは何かなど深く掘り下げてヒアリングさせていただき、<br />
									コンセプトに基づきブランドビジュアルを一緒につくっていきます。</p>
								<div className="col-flex">
									<img src={`${process.env.PUBLIC_URL}/images/service/branding-text01.svg`} alt="Plannning 企画" />
									<img src={`${process.env.PUBLIC_URL}/images/service/branding-text02.svg`} alt="Marketing マーケティング" />
									<img src={`${process.env.PUBLIC_URL}/images/service/branding-text03.svg`} alt="design デザイン" />
								</div>
								<div className="icon-flex">
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/branding-icon01.svg`} alt="ロゴマーク" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/branding-icon02.svg`} alt="パンフレット" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/branding-icon03.svg`} alt="チラシ" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/branding-icon04.svg`} alt="パッケージデザイン" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/branding-icon05.svg`} alt="ダイレクトメール" />
									</div>
									<div className="icon-col nbg">&nbsp;</div>
								</div>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function ServiceBlock05() {
	return (
		<>
			<div className="service-marketing bg2">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-subtitle rv">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/marketing.svg`} alt="marketing" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-marketing-flex rv">
							<div className="service-marketing-col img">
								<img src={`${process.env.PUBLIC_URL}/images/service/marketing-img01.svg`} alt="marketing" />
							</div>
							<div className="service-marketing-col">
								<h4 className="service-h4">集客にしっかり結果を出すSEO・MEO対策、<br />リスティング広告をご提案。</h4>
								<p className="service-text">SEO対策とは、Yahoo!やGoogleで検索をしたときに、検索結果の上位表示をさせる施策です。また、MEO対策とは、地域とカテゴリで検索する来店意欲の高いユーザーに対し、Googleマップの上位表示をおこなう施策です。<br />
									リスティング広告とは、検索エンジンの検索結果にユーザーが検索したキーワードに連動して掲載される広告のことを指します。<br />
									『検索をする＝ニーズがある』だから見込み客にしっかりPRできます。<br />
									<br />
									せっかく作ったホームページも誰の目にも留まらなければもったいないですよね？<br />
									見てもらいたい人を絞ってより多くの顧客へのアプローチを一緒に目指しましょう！</p>
								<div className="col-flex">
									<img src={`${process.env.PUBLIC_URL}/images/service/marketing-text01.svg`} alt="SEO SEO対策" />
									<img src={`${process.env.PUBLIC_URL}/images/service/marketing-text02.svg`} alt="MEO MEO対策" />
									<img src={`${process.env.PUBLIC_URL}/images/service/marketing-text03.svg`} alt="Advertising operation 広告運用" />
								</div>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function ServiceBlock06() {
	return (
		<>
			<div className="service-operation bg1">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/operation.svg`} alt="operation" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="service-operation-flex">
							<div className="service-operation-col img">
								<img src={`${process.env.PUBLIC_URL}/images/service/operation-img01.svg`} alt="operation" />
							</div>
							<div className="service-operation-col">
								<h4 className="service-h4">各S N S の運用代行は<br />プロにお任せください！</h4>
								<p className="service-text">忙しくて手が回らない…けど始めなきゃと思っている、初めてだから何をどうすれば良いかわからない…。そんなお悩み解消いたします！<br />
									投稿から運営までまるっとトータルサポートいたします。</p>
								<div className="col-flex">
									<img src={`${process.env.PUBLIC_URL}/images/service/operation-text01.svg`} alt="Plannning 企画" />
									<img src={`${process.env.PUBLIC_URL}/images/service/operation-text02.svg`} alt="Strategy 戦略" />
									<img src={`${process.env.PUBLIC_URL}/images/service/operation-text03.svg`} alt="Consulting コンサルティング" />
								</div>
								<div className="icon-flex">
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon01.svg`} alt="LINE公式アカウント運用代行" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon02.svg`} alt="Instagram運用代行" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon03.svg`} alt="Facebook運用代行" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon04.svg`} alt="ブログ代行" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon05.svg`} alt="Twitter運用代行" />
									</div>
									<div className="icon-col">
										<img src={`${process.env.PUBLIC_URL}/images/service/operation-icon06.svg`} alt="YouTube運用代行" />
									</div>
								</div>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}

function ContentsFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="servicefooter" composition="41BCB5191BDAA0419F21A0A95352CCE6" />
			</div>

		</>
	);
}
export default Service;
