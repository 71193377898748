import React, { useEffect, useState, useLayoutEffect } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import AnimateCC from "react-adobe-animate";
import queryString from 'query-string';
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import "./Works.scss";
import {
	Link,
} from "react-router-dom";

function Workscategory() {
	const search = useLocation().search;
	const query3 = queryString.parse(search);
	const urltext = "https://web-shop.co.jp/worklist/wp-json/wp/v2/";
	const client = Array();
	const worktype = Array();
	const [cdata, setCategory] = useState(0);
	const [data, setData] = useState([]);
	var qString;
	var lnum = 0;
	const [thispos, posChange] = useState([]);
	var cname = "";
	useLayoutEffect(() => {
		if (!cdata) {
			axios
				.get(urltext + "categories?per_page=100")
				.then(response => setCategory(response.data))
				.catch(error => console.log(error));
		}
	}, []);
	qString = "posts?_embed&per_page=100&categories=";
	var cont;
	var jsonurl = (urltext + qString);
	const workArray = Array();
	let nowPos = 0;
	var allResponse = [];
	var viewContents;
	var pp = 3;
	if (query3["c"] == "tc") {
		pp = 1;
	}
	function getCategory(cat) {
		lnum++;
		axios.get(urltext + "posts", { params: { categories: cat, per_page: 100 } })
			.then((responses) => {
				// 必要ならこの辺でレスポンスを加工
				allResponse = responses.data;
				//return Promise.resolve(allResponse);
				viewContents = setElement(allResponse, query3["c"]);
				setData(viewContents);
			});
	}
	function getAll() {
		lnum++;
		axios.get(urltext + qString + query3["c"])
			.then((responses) => {
				// 必要ならこの辺でレスポンスを加工
							allResponse = responses.data;
				viewContents = setElement(allResponse, query3["c"]);
				setData(viewContents);
			});
	}
	getAll();
	function setElement(val, qry) {
		for(var c = 0;c < cdata.length; c++){
			if(cdata[c].id == query3["c"]){
				cname = cdata[c].name;
			}
		}
var settext = "";
		if (val.length > 0) {
			settext += '<div class="works-list-title">' + cname + '</div>\n';
			settext += '<div class="works-list">'
			for (let ss = 0; ss < val.length; ss++) {
				settext += '<div class="works-list-col">\n';
				/*settext += '<div className="works-list-title">' + val[worktype[s].id][ss].title.rendered + '</div>\n';*/
				settext += '<div class="works-list-container">\n';
				settext += '<a href="/worksdetail/?p=' + val[ss].id + '">\n';
				settext += '<img src="' + val[ss].acf.photo1.replace(".jpg","-300x300.jpg") + '" alt="' + val[ss].title.rendered + '" />\n';
				settext += '</a>\n';
				settext += '</div>\n';
				settext += '</div>\n';
			}
			settext += "</div>";
		}
		return settext;
	}
	return (
		<>
			<div className="title-flex">
				<WorksCloud />
				<WorksTitle />
			</div>
			<div className="container">
				<Worksblock01 />
				<div className="works" dangerouslySetInnerHTML={{ __html: data }} />
			</div>
			<ContentsFooer />
		</>
	);
}
function WorksCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function WorksTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="service-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-works.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}
function Worksblock01() {
	return (
		<>
			<div className="submenu">
				<div className="submenu-btn">
					<Link to="/works/" className="active">TYPE OF WORK</Link>
					<Link to="/worksclient/">TYPE OF CLIENT</Link>
					<Link to="/worksall/">ALL</Link>
				</div>
			</div>

		</>
	)
}
function Worksblock02() {
	return (
		<>
			<div className="works-list">

			</div>
		</>
	)

}
function ContentsFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="servicefooter" composition="41BCB5191BDAA0419F21A0A95352CCE6" />
			</div>

		</>
	);
}

export default Workscategory;
