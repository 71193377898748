import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import AnimateCC from "react-adobe-animate";
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import updateAction from "./updateAction";
import './Contact.scss';

const Contact = props => {
	const { register, handleSubmit } = useForm();
	const { actions, state } = useStateMachine({ updateAction });
	const onSubmit = data => {
		actions.updateAction(data);
		props.history.push("/confirm");
	}
	const [paused, setPaused] = useState(false);
	const [, setAnimationObject] = useState(null);
	const getAnimationObject = obj => setAnimationObject(obj);
	const defaultValues = {
		inquiry: "選択してください",
	}
	const inquiry = ["ホームページ", "LP", "SEO", "MEO", "広告運用", "SNS運用", "ショッピングサイト", "システム開発", "紙媒体", "ブランディング", "補助金", "採用", "その他"];
	return (
		<>
			<div className="title-flex">
				<ContactCloud />
				<ContactTitle />
			</div>
			<div className="container">
				<div className="contact-flex">
					<div className="contact-flex-text">
						些細なことでもお気軽にお問合せください。<br />
						まずは、お話しをきくことからスタートさせていただきます。<br />
						御社だけの世界にひとつのweb集客を提案させていただきます！
					</div>
					<div className="contact-flex-progress">
						<img src={`${process.env.PUBLIC_URL}/images/contact/progress01.svg`} alt="Step" />
					</div>
				</div>
				<div className="contact-form">
					<form onSubmit={handleSubmit(onSubmit)}>
						<table className="contact-form-table">
							<tbody>
								<tr>
									<th className="contact-form-th">会社名</th>
									<td className="contact-form-td"><input className="input-width" type="text" placeholder="会社名" {...register("company", { required: false, maxLength: 12 })} defaultValue={state.company} /></td>
								</tr>
								<tr>
									<th className="contact-form-th">お名前<span className="required">*</span></th>
									<td className="contact-form-td"><input className="input-width" type="text" placeholder="お名前" {...register("yourname", { required: true, maxLength: 80 })} defaultValue={state.yourname} /></td>
								</tr>
								<tr>
									<th className="contact-form-th">電話番号</th>
									<td className="contact-form-td"><input className="input-width" type="tel" placeholder="電話番号" {...register("telnumber", {})} defaultValue={state.telnumber} /></td>
								</tr>
								<tr>
									<th className="contact-form-th">メールアドレス<span className="required">*</span></th>
									<td className="contact-form-td"><input className="input-width" type="email" placeholder="メールアドレス" {...register("mailaddress", { required: true, pattern: /^\S+@\S+$/i })} defaultValue={state.mailaddress} /></td>
								</tr>
								<tr>
									<th className="contact-form-th">お問い合わせ内容<span className="required">*</span></th>
									<td className="contact-form-td"><select className="input-width" {...register("inquiry",{required:true})}>
										{inquiry.map(inquirylist => (
											<option value={inquirylist}>
												{inquirylist}
											</option>
										))}
									</select></td>
								</tr>
								<tr>
									<th className="contact-form-th">お問い合わせ内容詳細<span className="required">*</span></th>
									<td className="contact-form-td"><textarea className="input-width input-textarea" {...register("message", { required: true })} defaultValue={state.message} /></td>
								</tr>
								<tr>
									<th className="contact-form-th"></th>
									<td className="contact-form-td contact-button"><input className="contact-btn-submit" type="submit" value="CONFIRM" /></td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>
			<ContactFooer />
		</>
	);
}
function ContactCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function ContactTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="contact-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-contact.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}

function ContactFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="contactfooter" composition="910D0EA391701E4089234698376BC213" />
			</div>

		</>
	);
}

export default withRouter(Contact);
