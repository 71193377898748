import React from "react";
import AnimateCC from "react-adobe-animate";
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import "./About.scss";
import {
	Link,
} from "react-router-dom";

function About() {
	L.Icon.Default.imagePath = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/';
	const Animation = {
		delay: 1000
	}
	return (
		<>
			<div className="title-flex">
				<AboutCloud />
				<AboutTitle />
			</div>
			<AboutBlock01 />
			<AboutBlock02 />
			<AboutBlock03 />
			<ContentsFooer />
		</>
	);
}
function AboutCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function AboutTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="about-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-about.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}
function AboutBlock01() {
	return (
		<>
			<div className="about-vision">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-vision-flex">
							<div className="about-vision-col bg">
								<h4 className="about-h4">お買い物をするときのような<br />ワクワクを。</h4>
								<p className="about-text">ちょっとしたアイデアやちょっとしたみせ方で<br />
									企業の魅力は劇的に変わります。<br />
									企業の想いを表現、形にし、ひとりでも多くの方に伝わるように、企業を盛り上げるお手伝いをすることが私たちの役目だと思っています。<br />
									<br />
									お買い物をするときのように楽しく、ワクワクしながら企業づくりができるよう、私たちは日々成長し続けていくことで社会に貢献し続けていきます。</p>
							</div>
							<div className="about-vision-col img">
								<img src={`${process.env.PUBLIC_URL}/images/about/about-illust01.svg`} alt="Step" />
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function AboutBlock02() {
	return (
		<>
			<div className="about-block02">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/mission.svg`} alt="Mission" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-block02-flex rv">
							<div className="about-block02-col bg">
								<h4 className="about-block02-h4">女性が社会とつながる場をつくる</h4>
							</div>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/avision.svg`} alt="Vision" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-block02-flex rv">
							<div className="about-block02-col bg">
								<h4 className="about-block02-h4">自分らしく働く社会を実現する</h4>
							</div>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-subtitle">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/value.svg`} alt="Value" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-block02-flex rv">
							<div className="about-block02-col bg">
								<table className="about-block02-table">
									<tbody>
										<tr>
											<th>
												<h4 className="about-block02-h4">進化する</h4>
											</th>
											<td>
												<p className="about-text">学び成長し豊かな人になる</p>
											</td>
										</tr>
										<tr>
											<th>
												<h4 className="about-block02-h4">体験を提供する</h4>
											</th>
											<td>
												<p className="about-text">基礎徹底し、<br />お客様、関わる方に感動体験を提供する</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function AboutBlock03() {
	const position = [35.7781, 139.495921];
	const zoom = 17;
	return (
		<>
			<div className="about-company">
				<div className="container">
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-subtitle rv">
							<h3><img src={`${process.env.PUBLIC_URL}/images/subtitle/companyprofile.svg`} alt="CompanyProfile" /></h3>
						</div>
					</Reveal>
					<Reveal animation={Animation.FadeInUp}>
						<div className="about-company-flex">
							<div className="about-company-col img">
								<img src={`${process.env.PUBLIC_URL}/images/about/about-illust03.svg`} alt="webshop" />
							</div>
							<div className="about-company-col">
								<table className="about-company-table">
									<tbody>
										<tr className="about-company-tr">
											<th className="about-company-th">会社名</th>
											<td className="about-company-td">webshop株式会社</td>
										</tr>
										<tr className="about-company-tr">
											<th className="about-company-th">代表取締役</th>
											<td className="about-company-td">阿部 智恵子</td>
										</tr>
										<tr className="about-company-tr">
											<th className="about-company-th">所在地</th>
											<td className="about-company-td">〒189-0001　東京都東村山市秋津町 5-8-27 ウィステリア202</td>
										</tr>
										<tr className="about-company-tr">
											<th className="about-company-th">TEL</th>
											<td className="about-company-td">042-306-0450</td>
										</tr>
										<tr className="about-company-tr">
											<th className="about-company-th">FAX</th>
											<td className="about-company-td">042-306-0495</td>
										</tr>
										<tr>
											<td colSpan={2}>
												<MapContainer center={position} zoom={zoom}>
													<TileLayer
														attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
														url="https://tile.openstreetmap.jp/{z}/{x}/{y}.png"
													/>
													<Marker position={position}>
														<Popup>
															webshop株式会社
														</Popup>
													</Marker>
												</MapContainer>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</Reveal>
				</div>
			</div>
		</>
	);
}
function ContentsFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="aboutfooter" composition="31D0B4B105540C4E926F020720BDDCE3" />
			</div>

		</>
	);
}
export default About;
