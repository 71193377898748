import React from "react";
import {Link,} from "react-router-dom";
import "./Header.scss";

function Headermenu() {
    let Items = [
        //{ "Name": "Home", "Target": "/" },
        { "Name": "About", "Target": "/about/" },
        { "Name": "Service", "Target": "/service/" },
        { "Name": "Works", "Target": "/works/" },
        { "Name": "Contact", "Target": "/contact/" },
    ];
    return (
        <>
        <header id="navArea">
            <div className="mw">
            <div className="logo"><Link to="/"><h1><img src={`${process.env.PUBLIC_URL}/images/header-logo.svg`} alt="" /></h1></Link></div>
            <div className="menu">
                <div id="nav-drawer">
                    <input id="nav-input" type="checkbox" className="nav-unshown" />
                    <label id="nav-open" htmlFor="nav-input"><span></span></label>
                    <div className="close-text">close</div>
                    <label className="nav-unshown" id="nav-close" htmlFor="nav-input"></label>
                    <div id="nav-content">
                        <div className="menu-category">
                            {Items.map((item) => (
                                <div className="menu-category-title" key={item.Name}><Link className="menu-category-title-btn" to={item.Target}><img src={`${process.env.PUBLIC_URL}/images/menu-${item.Name}.svg`} alt={item.Name} /><img className="hoverimg" src={`${process.env.PUBLIC_URL}/images/menu-${item.Name}-r.svg`} alt={item.Name} /></Link></div>
                            ))}
                            <div className="menu-category-fb"><a href="https://www.facebook.com/webshopsan/" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/btn-fb-b.svg`} alt="" /></a></div>
                        </div>
                        <div className="image">
                            <img src={`${process.env.PUBLIC_URL}/images/menuicon01.svg`} alt="webshop株式会社" />
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div id="nav-bg"></div>
        </header>
        </>
    )
    
}

export default Headermenu;