import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import AnimateCC from "react-adobe-animate";
import queryString from 'query-string';
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs } from 'swiper/core';
import 'swiper/css';
import "./Works.scss";
import {
	Link,
} from "react-router-dom";
 // install Swiper's Thumbs component
 SwiperCore.use([Thumbs]);
function Worksdetail() {
	return (
		<>
			<div className="title-flex">
				<WorksCloud />
				<WorksTitle />
			</div>
			<div className="container">
				<Worksdetailblock />
			</div>
			<ContentsFooer />
		</>
	);
}
function WorksCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function WorksTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="service-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-works.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}
function Worksdetailblock() {
	const dsearch = useLocation().search;
	const dquery3 = queryString.parse(dsearch);
	//const durltext = "https://webshop.test-server.shop/worklist/wp-json/wp/v2/";
	const durltext = "https://web-shop.co.jp/worklist/wp-json/wp/v2/";
	const dclient = Array();
	const dworktype = Array();
	const [ddata, dsetData] = useState([]);
	var qString;
	qString = "posts/" + dquery3.p;
	var dcont;
	var djsonurl = (durltext + qString);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	useEffect(() => {
		axios
			.get(durltext + qString)
			.then(response => dsetData(response.data))
			.catch(error => console.log(error));
	}, []);
	dcont = ddata;
	var swparams = {
		autoplay: {
			delay: 5000,
		  },
		  thumbs: {
			swiper: thumbsSwiper ,
		  }
	}
	if (dcont != "") {
		return (
			<>
				<div className="detail">
					<div className="detail-img">
						<div className="detail-title">
							<img src={`${process.env.PUBLIC_URL}/images/works/detail-titlepin.svg`} alt="" />
							<div className="detail-title-text" dangerouslySetInnerHTML={{ __html: dcont.title.rendered }}></div>
						</div>
						<Swiper className="detail-slider-main" {...swparams}>
						
							{dcont.acf.photo1 && <SwiperSlide><img src={dcont.acf.photo1} /></SwiperSlide>}
							{dcont.acf.photo2 && <SwiperSlide><img src={dcont.acf.photo2} /></SwiperSlide>}
							{dcont.acf.photo3 && <SwiperSlide><img src={dcont.acf.photo3} /></SwiperSlide>}
							{dcont.acf.photo4 && <SwiperSlide><img src={dcont.acf.photo4} /></SwiperSlide>}
							{dcont.acf.photo5 && <SwiperSlide><img src={dcont.acf.photo5} /></SwiperSlide>}
						</Swiper>
						<Swiper className="detail-slider-nav" slidesPerView={5} onSwiper={setThumbsSwiper}>
							{dcont.acf.photo1 && <SwiperSlide className="detail-slider-nav-btn first"><img src={dcont.acf.photo1} /></SwiperSlide>}
							{dcont.acf.photo2 && <SwiperSlide className="detail-slider-nav-btn"><img src={dcont.acf.photo2} /></SwiperSlide>}
							{dcont.acf.photo3 && <SwiperSlide className="detail-slider-nav-btn"><img src={dcont.acf.photo3} /></SwiperSlide>}
							{dcont.acf.photo4 && <SwiperSlide className="detail-slider-nav-btn"><img src={dcont.acf.photo4} /></SwiperSlide>}
							{dcont.acf.photo5 && <SwiperSlide className="detail-slider-nav-btn last"><img src={dcont.acf.photo5} /></SwiperSlide>}
						</Swiper>
					</div>
					<div className="detail-text">
						<div className="detail-text-area">
							<div dangerouslySetInnerHTML={{ __html: dcont.content.rendered }}></div>
							<div className="detail-text-name">
								<div dangerouslySetInnerHTML={{ __html: dcont.title.rendered }}></div>
							</div>
						</div>
						<div className="detail-back">
							<a className="detail-back-btn" href="../works/">Back</a>
						</div>
					</div>
				</div>
			</>
		)
	} else {
		return (
			<>

			</>
		);
	}
}
function ContentsFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="servicefooter" composition="41BCB5191BDAA0419F21A0A95352CCE6" />
			</div>

		</>
	);
}

export default Worksdetail;
