import React from "react";
import {
    Link,
} from "react-router-dom";
import "./Footermenu.scss";

function Footermenu() {
    let Items = [
        { "Name": "Home", "Target": "/" },
        { "Name": "About", "Target": "/about/" },
        { "Name": "Service", "Target": "/service/" },
        //{ "Name": "Works", "Target": "/works/" },
        { "Name": "Contact", "Target": "/contact/" },
    ];
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <div className="footer-flex">
                        <div className="footer-logo">
                        <Link to="/"><img src={`${process.env.PUBLIC_URL}/images/footer-logo.svg`} alt="" /></Link>
                        </div>
                        <div className="footer-menu">
                            {Items.map((item) => (
                                <div className="footer-menu-list" key={item.Name}><Link className="footer-menu-btn" to={item.Target}>{item.Name}</Link></div>
                            ))}
                            <div className="footer-menu-fb"><a href="https://www.facebook.com/webshopsan/" target="_blank"><img src={`${process.env.PUBLIC_URL}/images/btn-fb-w.svg`} alt="" /></a></div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default Footermenu;