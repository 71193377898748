import React from "react";
import { StateMachineProvider, createStore } from "little-state-machine";
import "./App.scss";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
} from "react-router-dom";
import { ReactGenieAnimations } from "react-genie-styled-components";
import ScrollToTop from './ScrollToTop';
import Home from './Home';
import Headermenu from './Headermenu';
import Footermenu from './Footermenu';
import Service from './Service';
import About from './About';
import Works from './Works';
import Worksclient from './Worksclient';
import Worksall from './Worksall';
import Workscategory from './Workscategory';
import Worksdetail from './detail';
import Contact from './Contact';
import Confirmation from './contact/confirm';
import Thanks from './contact/thanks';

createStore({
	data: {
	  company: '',
	  yourname: '',
	  telnumber: '',
	  mailaddress: '',
	  message: '',
	}
  });

function App() {
	return (
		<>
		<StateMachineProvider>
			<Router basename="/">
			<ScrollToTop />
			<Headermenu />
			<main>
			<ReactGenieAnimations />
				<Switch>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/about">
						<About />
					</Route>
					<Route path="/service">
						<Service />
					</Route>
					<Route path="/works">
						<Works />
					</Route>
					<Route path="/worksclient">
						<Worksclient />
					</Route>
					<Route path="/worksall">
						<Worksall />
					</Route>
					<Route path="/workscategory">
						<Workscategory />
					</Route>
					<Route path="/worksdetail">
						<Worksdetail />
					</Route>
					<Route path="/contact">
						<Contact />
					</Route>
					<Route path="/confirm">
						<Confirmation />
					</Route>
					<Route path="/thanks">
						<Thanks />
					</Route>
				</Switch>
				</main>
				<Footermenu />
			</Router>
			</StateMachineProvider>
		</>
	);
}
export default App;