import React, { useState } from 'react';
import axios from "axios";
import { useForm } from 'react-hook-form';
import { withRouter } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import AnimateCC from "react-adobe-animate";
import { Reveal } from 'react-genie';
import { Animation } from "react-genie-styled-components";
import updateAction from "../updateAction";
import clearAction from "../clearAction";
import '../Contact.scss';

const Confirmation = (props) => {
	const { register, handleSubmit, watch, reset, errors, getValues } = useForm();
	const { state } = useStateMachine(updateAction);
	const { actions } = useStateMachine({clearAction});
	const onSubmitData = async () => {
		try {
			const res = await axios.post("/mail/mail.php", state);
			console.log(res);
			actions.clearAction();
			if(!res.data.error){
			props.history.push("/thanks");
			}else{
				console.log(res);
				props.history.push("/contact");
			}
		} catch {
		}
	}
	if(Object.keys(state).length == 0){
		props.history.push("/contact");
	}
	const onBacktData = (data) => {
		props.history.push("/contact");
	}
	return (
		<>
			<div className="title-flex">
				<ContactCloud />
				<ContactTitle />
			</div>
			<div className="container">
				<div className="contact-flex">
					<div className="contact-flex-text">
						ご入力いただいた内容をご確認いただき、問題ないようでしたら<br className="pc" />
						「COMPLETE」ボタンを押して送信してください。<br />
						内容を変更したい場合は、「REPAIR」から入力画面にお戻りください。
					</div>
					<div className="contact-flex-progress">
						<img src={`${process.env.PUBLIC_URL}/images/contact/progress02.svg`} alt="Step" />
					</div>
				</div>
				<div className="contact-confirm">
					<form>
						<table className="contact-confirm-table">
							<tbody>
								<tr>
									<th className="contact-confirm-th">会社名</th>
									<td className="contact-confirm-td">{state.company}</td>
								</tr>
								<tr>
									<th className="contact-confirm-th">お名前<span className="required">*</span></th>
									<td className="contact-confirm-td">{state.yourname}</td>
								</tr>
								<tr>
									<th className="contact-confirm-th">電話番号</th>
									<td className="contact-confirm-td">{state.telnumber}</td>
								</tr>
								<tr>
									<th className="contact-confirm-th">メールアドレス<span className="required">*</span></th>
									<td className="contact-confirm-td">{state.mailaddress}</td>
								</tr>
								<tr>
									<th className="contact-confirm-th">お問い合わせ内容<span className="required">*</span></th>
									<td className="contact-confirm-td">{state.inquiry}</td>
								</tr>
								<tr>
									<th className="contact-confirm-th">お問い合わせ内容詳細<span className="required">*</span></th>
									<td className="contact-confirm-td"><div className=" input-textarea">{state.message}</div></td>
								</tr>
								<tr>
									<th className="contact-form-th"></th>
									<td className="contact-form-td contact-button"><input className="contact-btn-back" onClick={onBacktData} type="button" value="REPAIR" /><input className="contact-btn-submit" onClick={onSubmitData} type="button" value="COMPLETE" /></td>
								</tr>
							</tbody>
						</table>
					</form>
				</div>
			</div>
			<ContactFooer />
		</>
	);
}
function ContactCloud() {
	return (
		<>
			<div className="cloud">
				<div className="cloud1"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud1.svg`} alt="cloud" /></div></div>
				<div className="cloud2"><div className="cloudparts"><img className="cloudmove" src={`${process.env.PUBLIC_URL}/images/cloud2.svg`} alt="cloud" /></div></div>
			</div>
		</>
	);
}
function ContactTitle() {
	return (
		<>
			<Reveal animation={Animation.FadeInUp}>
				<div className="contact-title">
					<h2><img src={`${process.env.PUBLIC_URL}/images/title/title-contact.svg`} alt="Step" /></h2>
				</div>
			</Reveal>
		</>
	);
}

function ContactFooer() {
	return (
		<>
			<div className="content-footer">
				<AnimateCC animationName="contactfooter" composition="910D0EA391701E4089234698376BC213" />
			</div>

		</>
	);
}

export default withRouter(Confirmation);